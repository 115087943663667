@import '../../base.scss';

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;

    &:hover{
        transition: all .3s;
        cursor: pointer;
        filter: contrast(60%);
        
    }
}

.box_image{
    width: 100%;
    aspect-ratio: 4/3;
    overflow: hidden;
}

.image{
    width: 100%;
    height: auto;
}

.text{
    box-sizing: border-box;
    width: 100%;
    min-height: 74px;
    color: $text-box;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0 0;
    padding: 10px;
    margin: 0;
}