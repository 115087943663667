@import '../../base.scss';


    .main {
        max-width: 100vw;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        box-sizing: border-box;
    }

    .overlay{
        max-width: 100%;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(1, 15, 23, 0.3);
    }

.container{
    box-sizing: border-box;
    max-width: 1200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item {
    max-width: 100%;
    width: 100%;
    color: $white;
    background-image: url(../../image/InitialPage/img.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
}

.title{
    max-width: 900px;
    font-size: 56px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0;
    position: relative;
    text-transform: uppercase;
}

.line{
    background: $element;
    height: 3px;
    width: 50px;
    margin: 30px 0 20px; 
}

.text{
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    text-align: left;
    margin: 0;
    color: rgba($white, .8)
}

.button{
    background-color: $element;
    text-transform: uppercase;
    color: $white;
    border: none;
    width: 270px;
    height: 50px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 20px;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: all .3s ease-out;
        box-shadow: 0px 0px 16px 8px rgba($element, 0.25), 0px 0px 8px 8px rgba($element, 0.25);
    }
}

@media(max-width: 930px){
    .title{
        max-width: 700px;
        font-size: 44px;
    }

    .text{
        font-size: 20px;
    }

    .container{
        padding: 0;
    }
}

@media(max-width: 768px){
    .main {
        justify-content: flex-start;
        align-items: center;
    }

    .title{
        font-size: 38px;
        max-width: 500px;
    }

    .text{
        font-size: 20px;
    }

    .line{
        background: $element;
        height: 3px;
        width: 30px;
        margin: 20px 0 10px; 
    }
}

@media(max-width: 500px){

    .title{
        max-width: 300px;
        font-size: 30px;
    }

    .text{
        font-size: 18px;
    }
}

@media(max-width: 425px){
    .item {
        max-width: 100%;
        width: 100%;
        color: $white;
        background-image: url(../../image/InitialPage/img_1.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .box{
        max-width: 100%;
    }

    .title{
        font-size: 25px;
    }

    .button{
        width: 160px;
        height: 40px;
        font-size: 12px;
    }
}