@import '../../base.scss';

.hint {
    box-sizing: border-box;
    background-color: $background;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid $text-box;
}

.box {
    background-color: $background;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    &:hover {
        
        & h3{
            color: $element;
        }
        cursor: pointer;
        transform: .3s all
    }
}

.hint_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hint_circle{
    box-sizing: border-box;
    min-width: 10px;
    height: 10px;
    margin-right: 20px;
    background-color: $element;
    border-radius: 50%;
}

.title {
    font-size: 18px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0;
    color: $title;
    text-align: left;
}

.text{
    @include text;
    text-align:justify;
    width: 90%;
    margin: 0 0 10px;
    color: $text-box;
}

.hint_image{
    width: 14px;
    height: 11px;
    margin-left: 20px;
    transition: all 1s ease;
}

.hint_active{
    width: 14px;
    height: 11px;
    transition: all 1s ease;
    transform: rotate(-180deg);
}